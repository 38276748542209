<template>
  <AdhesiveFormSingle/>
  <AdhesiveFormWhole/>
  <BannerFormSingle/>
  <BannerFormWhole/>
  <LaserFormSingle/>
  <LaserFormWhole/>
  <OffsetFormSingle/>
  <OffsetFormWhole/>
  <FlayersFormSingle/>
  <FlayersFormWhole/>
  <DiscountFormSingle/>
  <DiscountFormWhole/>
  <div class="main">
    <h1>Широкоформатная печать рекламных макетов</h1>
    <h2>Печать на самоклеящихся плёнках</h2>
    <template v-for="el in selfAdhesive" :key="el.id">
      <TableComponent :image-url="el.attributes.image" :st-count="el.attributes.standardCount"
                      :st-value="el.attributes.standardValue"
                      :whole-count="el.attributes.wholeCount"
                      :whole-value="el.attributes.wholeValue"
                      :name="el.attributes.title"
                      :description="el.attributes.description"/>
    </template>
    <div class="buy-buttons">
      <button type="button" @click="singleAdhesive">Заказать штучно</button>
      <button type="button" @click="wholeAdhesive">Заказать оптом</button>
    </div>
    <h2>Печать на баннерной ткани и сетке</h2>
    <template v-for="el in banners" :key="el.id">
      <TableComponent :image-url="el.attributes.image" :st-count="el.attributes.standardCount"
                      :st-value="el.attributes.standardValue"
                      :whole-count="el.attributes.wholeCount"
                      :whole-value="el.attributes.wholeValue"
                      :name="el.attributes.title"
                      :description="el.attributes.description"/>
    </template>
    <div class="buy-buttons">
      <button type="button" @click="bannerActive">Заказать штучно</button>
      <button type="button" @click="bannerWholeActive">Заказать оптом</button>
    </div>
    <h2>Визитные карточки. Лазерная печать (от 100 шт)</h2>
    <template v-for="el in laserData" :key="el.id">
      <TableComponent :image-url="el.attributes.image" :st-count="el.attributes.standardCount"
                      :st-value="el.attributes.standardValue"
                      :whole-count="el.attributes.wholeCount"
                      :whole-value="el.attributes.wholeValue"
                      :name="el.attributes.title"
                      :description="el.attributes.description"/>
    </template>
    <div class="buy-buttons">
      <button type="button" @click="laserActive">Заказать штучно</button>
      <button type="button" @click="laserWholeActive">Заказать оптом</button>
    </div>
    <h2>Визитные карточки. Офсетная печать (от 1 000 шт)</h2>
    <template v-for="el in offset" :key="el.id">
      <TableComponent :image-url="el.attributes.image" :st-count="el.attributes.standardCount"
                      :st-value="el.attributes.standardValue"
                      :whole-count="el.attributes.wholeCount"
                      :whole-value="el.attributes.wholeValue"
                      :name="el.attributes.title"
                      :description="el.attributes.description"/>
    </template>
    <div class="buy-buttons">
      <button type="button" @click="offsetActive">Заказать штучно</button>
      <button type="button" @click="offsetWholeActive">Заказать оптом</button>
    </div>
    <h2>Флаеры, листовки, календари. Офсетная печать</h2>
    <template v-for="el in flayers" :key="el.id">
      <TableComponent :image-url="el.attributes.image" :st-count="el.attributes.standardCount"
                      :st-value="el.attributes.standardValue"
                      :whole-count="el.attributes.wholeCount"
                      :whole-value="el.attributes.wholeValue"
                      :name="el.attributes.title"
                      :description="el.attributes.description"/>
    </template>
    <div class="buy-buttons">
      <button type="button" @click="flayersActive">Заказать штучно</button>
      <button type="button" @click="flayersWholeActive">Заказать оптом</button>
    </div>
    <h2>Дисконтные карты</h2>
    <template v-for="el in discount" :key="el.id">
      <TableComponent :image-url="el.attributes.image" :st-count="el.attributes.standardCount"
                      :st-value="el.attributes.standardValue"
                      :whole-count="el.attributes.wholeCount"
                      :whole-value="el.attributes.wholeValue"
                      :name="el.attributes.title"
                      :description="el.attributes.description"/>
    </template>
    <div class="buy-buttons">
      <button type="button" @click="discountActive">Заказать штучно</button>
      <button type="button" @click="discountWholeActive">Заказать оптом</button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
import AdhesiveFormSingle from '@/components/PrintPage/AdhesiveFormSingle.vue';
import AdhesiveFormWhole from '@/components/PrintPage/AdhesiveFormWhole.vue';
import BannerFormSingle from '@/components/PrintPage/BannerFormSingle.vue';
import BannerFormWhole from '@/components/PrintPage/BannerFormWhole.vue';
import LaserFormSingle from '@/components/PrintPage/LaserFormSingle.vue';
import LaserFormWhole from '@/components/PrintPage/LaserFormWhole.vue';
import OffsetFormSingle from '@/components/PrintPage/OffsetFormSingle.vue';
import OffsetFormWhole from '@/components/PrintPage/OffsetFormWhole.vue';
import FlayersFormSingle from '@/components/PrintPage/FlayersFormSingle.vue';
import FlayersFormWhole from '@/components/PrintPage/FlayersFormWhole.vue';
import DiscountFormSingle from '@/components/PrintPage/DiscountFormSingle.vue';
import DiscountFormWhole from '@/components/PrintPage/DiscountFormWhole.vue';
import {
  getTableData, getBannerData, getLaserPrintData, getOffsetPrintData, getFlayersData,
  getDiscountsData,
} from '../api';

export default {
  name: 'PrintView',
  components: {
    TableComponent,
    AdhesiveFormSingle,
    AdhesiveFormWhole,
    BannerFormSingle,
    BannerFormWhole,
    LaserFormSingle,
    LaserFormWhole,
    OffsetFormSingle,
    OffsetFormWhole,
    FlayersFormSingle,
    FlayersFormWhole,
    DiscountFormSingle,
    DiscountFormWhole,
  },
  data: () => ({
    selfAdhesive: [],
    banners: [],
    laserData: [],
    offset: [],
    flayers: [],
    discount: [],
    ttr: null,
  }),
  async mounted() {
    try {
      const tableData = await getTableData();
      this.selfAdhesive = tableData;
      const bannerData = await getBannerData();
      this.banners = bannerData;
      const lasersData = await getLaserPrintData();
      this.laserData = lasersData;
      const offsetData = await getOffsetPrintData();
      this.offset = offsetData;
      const flayersData = await getFlayersData();
      this.flayers = flayersData;
      const discountData = await getDiscountsData();
      this.discount = discountData;
    } catch (error) {
      // console.log(error);
    }
  },
  methods: {
    ...mapMutations(['SET_ADHESIVE_ACTIVE', 'SET_ADHESIVE_WHOLE_ACTIVE', 'SET_BANNERS_ACTIVE', 'SET_BANNERS_WHOLE_ACTIVE', 'SET_LASER_ACTIVE', 'SET_LASER_WHOLE_ACTIVE', 'SET_OFFSET_WHOLE_ACTIVE', 'SET_OFFSET_ACTIVE', 'SET_FLAYERS_WHOLE_ACTIVE', 'SET_FLAYERS_ACTIVE', 'SET_DISCOUNT_ACTIVE', 'SET_DISCOUNT_WHOLE_ACTIVE']),
    singleAdhesive() {
      this.SET_ADHESIVE_ACTIVE(true);
    },
    wholeAdhesive() {
      this.SET_ADHESIVE_WHOLE_ACTIVE(true);
    },
    bannerActive() {
      this.SET_BANNERS_ACTIVE(true);
    },
    bannerWholeActive() {
      this.SET_BANNERS_WHOLE_ACTIVE(true);
    },
    laserActive() {
      this.SET_LASER_ACTIVE(true);
    },
    laserWholeActive() {
      this.SET_LASER_WHOLE_ACTIVE(true);
    },
    offsetActive() {
      this.SET_OFFSET_ACTIVE(true);
    },
    offsetWholeActive() {
      this.SET_OFFSET_WHOLE_ACTIVE(true);
    },
    flayersActive() {
      this.SET_FLAYERS_ACTIVE(true);
    },
    flayersWholeActive() {
      this.SET_FLAYERS_WHOLE_ACTIVE(true);
    },
    discountActive() {
      this.SET_DISCOUNT_ACTIVE(true);
    },
    discountWholeActive() {
      this.SET_DISCOUNT_WHOLE_ACTIVE(true);
    },
  },
};

</script>
<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
    color: $main-word-color;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .buy-buttons {
    display: flex;
    justify-content: flex-end;
    width: clamp(200px, 95%, 1165px);
    gap: 10px;

    button {
      margin-top: 10px;
      margin-bottom: 10px;
      border: none;
      cursor: pointer;
      color: $button-text-color-light;
      font-weight: 400;
      line-height: 25px;
      font-size: 14px;
      text-transform: uppercase;
      width: calc((100% - 70% - 65px) / 2);
      height: 44px;
      background: $button-color;
      border-radius: 20px;
      align-self: end;
    }
  }
}

@media(max-width: 950px) {
  .main {
    .buy-buttons {
      justify-content: center;
      gap: 50px;

      button {
        width: 200px;

        &:last-of-type {
          position: relative;

          &::after {
            content: '';
            width: 2px;
            height: 100%;
            background-color: $main-word-color;
            position: absolute;
            left: -25px;
            top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .main {
    .buy-buttons {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;

      button {
        align-self: center;
        margin-top: 0;
        margin-bottom: 0;

        &:last-of-type {
          margin-bottom: 10px;

          &::after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $main-word-color;
            position: absolute;
            top: -7px;
            left: 0;
          }
        }

      }
    }
  }
}
</style>
