<template>
  <div class="home">
    <TitleBlock/>
    <AboutBlock/>
    <UfBlock/>
    <SouvenirBlock/>
    <BrandBlock/>
    <AdvertisingBlock/>
    <PrizesBlock/>
  </div>
</template>

<script>

import TitleBlock from '@/components/HomePage/TitleBlock.vue';
import AboutBlock from '@/components/HomePage/AboutBlock.vue';
import BrandBlock from '@/components/HomePage/BrandBlock.vue';
import AdvertisingBlock from '@/components/HomePage/AdvertisingBlock.vue';
import SouvenirBlock from '@/components/HomePage/SouvenireBlock.vue';
import UfBlock from '@/components/HomePage/UfBlock.vue';
import PrizesBlock from '@/components/HomePage/PrizesBlock.vue';

export default {
  name: 'HomeView',
  components: {
    TitleBlock,
    AboutBlock,
    BrandBlock,
    AdvertisingBlock,
    SouvenirBlock,
    UfBlock,
    PrizesBlock,
  },
};
</script>

<style lang="scss" scoped>
</style>
