<template>
  <div class="brand-block">
    <div class="content">
      <div class="text">
        <h2>УФ печать</h2>
        <p>Яркие цвета <br> Краски не выгорают <br> Не требуют ламинации <br> Износостойкость
          изображения</p>
        <div class="brand-buttons">
          <router-link :active-class="'active-link'" class="nav-item" to="/UF-Print">
            <button type="button">подробнее</button>
          </router-link>
        </div>
      </div>
      <div class="city-light">
        <img src="@/assets/image/mimaki.png" alt="mimaki" class="city-light-obj">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UfBlock',
};
</script>

<style lang="scss" scoped>
.brand-block {
  height: 470px;
  width: 100%;
  background: #dedddb;
  overflow: hidden;

  .content {
    max-height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //gap: 60px;

    .text {
      width: 400px;
      margin-left: 20%;

      h2 {
        margin: 0 0 6px 0;
        font-weight: 500;
        font-size: 38px;
        line-height: 38px;
        color: $main-word-color;
        white-space: nowrap;
      }

      p {
        font-size: 18px;
        line-height: 23px;
        color: $text-dark;
        font-weight: 300;
        margin-bottom: 17px;
        width: 250px;
      }

      .brand-buttons {
        display: flex;
      }

      button {
        margin-top: 19px;
        border: none;
        cursor: pointer;
        color: $button-text-color-light;
        font-weight: 400;
        line-height: 25px;
        font-size: 16px;
        text-transform: uppercase;
        width: 140px;
        height: 44px;
        background: $button-color;
        border-radius: 30px;
        margin-right: 10px;
      }
    }

    .city-light {
      height: 420px;
      width: 700px;
      position: relative;
      padding-right: calc(100% - 200px);

      img {
        position: absolute;
        width: 100%;
        height: 90%;
        object-fit: contain;
      }

      .city-light-obj {
        top: 44px;
        right: 60px;
      }

      .car {
        height: 65%;
        top: 116px;
        right: 113px;
      }
    }
  }
}

@media (max-width: 1250px) {
  .brand-block {
    height: 420px;

    .content {
      .text {
        width: 250px;
        margin-left: 15%;
      }

      .city-light {
        height: 420px;
        width: 600px;
        position: relative;

        img {
          position: absolute;
          width: 100%;
          height: 90%;
          object-fit: contain;
        }

        .car {
          top: 117px;
          right: 137px;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .brand-block {
    height: 240px;

    .content {
      .text {
        margin-left: 15%;
        width: 250px;

        h2 {
          font-size: 23px;
        }

        p {
          font-size: 16px;
          margin-bottom: 5px;
        }

        button {
          height: 30px;
          font-size: 16px;
        }
      }

      .city-light {
        height: 240px;
        width: 400px;
        position: relative;
        padding-right: 0;

        .city-light-obj {
          right: 20px;
          top: 5px;
        }

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .car {
          height: 75%;
          top: 90px;
          right: 44px;
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .brand-block {
    .content {
      .text {
        position: unset;
        width: 70%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: 0;
        p{
          width: 200px;
        }
        .brand-buttons {
          flex-direction: column;
          justify-content: space-around;
          margin-bottom: 10px;
        }
      }

      .car {
        width: auto !important;
        right: 10px;
      }
    }
  }
}

@media (max-width: 640px) {
  .brand-block {
    height: 450px;

    .content {
      flex-direction: column-reverse;
      gap: 10px;

      .text {
        button {
          margin-top: 10px;
          width: 180px;
        }
      }

      .city-light {
        .car {
          top: 53px;
          right: 11px;
          height: 171px;
        }

        .city-light-obj {
          right: 0;
          top: 10px;
          height: 180px;
        }
      }
    }

  }
}
</style>
