import { createStore } from 'vuex';
import {
  getBrandData,
  getCalendarData, getClocksData,
  getCupsData, getDiplomsData, getFigurineData,
  getFlayerData, getLentiesData, getMedalistsData, getMedalsData, getPlaketsData,
  getPrizeData,
  getSouvenirData,
  getUfData, getUfPageInfoData,
} from '../api';

export default createStore({
  state: {
    burgerActive: false,
    adhesiveActive: false,
    adhesiveWholeActive: false,
    bannerActive: false,
    bannerWholeActive: false,
    laserActive: false,
    laserWholeActive: false,
    offsetActive: false,
    offsetWholeActive: false,
    flayersActive: false,
    flayersWholeActive: false,
    discountActive: false,
    discountWholeActive: false,
    UfList: [],
    SouvenirList: [],
    BrandList: [],
    CalendarList: [],
    FlayerList: [],
    PrizeList: [],
    CupList: [],
    MedalsList: [],
    PlaketsList: [],
    FigurinesList: [],
    ClocksList: [],
    DiplomsList: [],
    LentiesList: [],
    MedalnitsList: [],
    UfInfoList: [],
  },
  getters: {
    burgerActive: (state) => state.burgerActive,
    UfInfoList: (state) => state.UfInfoList,
    UfInfoId: (state) => (id) => state.UfInfoList.find((UfInfoId) => UfInfoId.id === id),
    UfList: (state) => state.UfList,
    UfId: (state) => (id) => state.UfList.find((UfId) => UfId.id === id),
    SouvenirList: (state) => state.SouvenirList,
    SouvenirID: (state) => (id) => state.SouvenirList.find((SouvenirID) => SouvenirID.id === id),
    BrandList: (state) => state.BrandList,
    BrandID: (state) => (id) => state.BrandList.find((BrandID) => BrandID.id === id),
    CalendarList: (state) => state.CalendarList,
    CalendarID: (state) => (id) => state.CalendarList.find((CalendarID) => CalendarID.id === id),
    FlayerList: (state) => state.FlayerList,
    FlayerID: (state) => (id) => state.FlayerList.find((FlayerID) => FlayerID.id === id),
    PrizeList: (state) => state.PrizeList,
    PrizeID: (state) => (id) => state.PrizeList.find((PrizeID) => PrizeID.id === id),
    CupList: (state) => state.CupList,
    CupID: (state) => (id) => state.CupList.find((CupID) => CupID.id === id),
    MedalsList: (state) => state.MedalsList,
    MedalsID: (state) => (id) => state.MedalsList.find((MedalsID) => MedalsID.id === id),
    PlaketsList: (state) => state.PlaketsList,
    PlaketsID: (state) => (id) => state.PlaketsList.find((PlaketsID) => PlaketsID.id === id),
    FigurinesList: (state) => state.FigurinesList,
    FigurinesID: (state) => (id) => state.FigurinesList.find((FigurinesID) => FigurinesID.id === id),
    ClocksList: (state) => state.ClocksList,
    ClocksID: (state) => (id) => state.ClocksList.find((ClocksID) => ClocksID.id === id),
    DiplomsList: (state) => state.DiplomsList,
    DiplomsID: (state) => (id) => state.DiplomsList.find((DiplomsID) => DiplomsID.id === id),
    LentiesList: (state) => state.LentiesList,
    LentiesID: (state) => (id) => state.LentiesList.find((LentiesID) => LentiesID.id === id),
    MedalnitsList: (state) => state.MedalnitsList,
    MedalnitsID: (state) => (id) => state.MedalnitsList.find((MedalnitsID) => MedalnitsID.id === id),

  },
  mutations: {
    toggle(state) {
      state.burgerActive = !state.burgerActive;
    },
    toggle_h(state) {
      state.burgerActive = !state.burgerActive;
    },
    SET_BURGER_ACTIVE(state, payload) {
      state.burgerActive = payload;
    },
    SET_ADHESIVE_ACTIVE(state, payload) {
      state.adhesiveActive = payload;
    },
    SET_ADHESIVE_WHOLE_ACTIVE(state, payload) {
      state.adhesiveWholeActive = payload;
    },
    SET_BANNERS_ACTIVE(state, payload) {
      state.bannerActive = payload;
    },
    SET_BANNERS_WHOLE_ACTIVE(state, payload) {
      state.bannerWholeActive = payload;
    },
    SET_LASER_ACTIVE(state, payload) {
      state.laserActive = payload;
    },
    SET_LASER_WHOLE_ACTIVE(state, payload) {
      state.laserWholeActive = payload;
    },
    SET_OFFSET_ACTIVE(state, payload) {
      state.offsetActive = payload;
    },
    SET_OFFSET_WHOLE_ACTIVE(state, payload) {
      state.offsetWholeActive = payload;
    },
    SET_FLAYERS_ACTIVE(state, payload) {
      state.flayersActive = payload;
    },
    SET_FLAYERS_WHOLE_ACTIVE(state, payload) {
      state.flayersWholeActive = payload;
    },
    SET_DISCOUNT_ACTIVE(state, payload) {
      state.discountActive = payload;
    },
    SET_DISCOUNT_WHOLE_ACTIVE(state, payload) {
      state.discountWholeActive = payload;
    },
    SET_UF_LIST(state, data) {
      state.UfList = data;
    },
    SET_SOUVENIR_LIST(state, data) {
      state.SouvenirList = data;
    },
    SET_BRAND_LIST(state, data) {
      state.BrandList = data;
    },
    SET_CALENDAR_LIST(state, data) {
      state.CalendarList = data;
    },
    SET_FLAYER_LIST(state, data) {
      state.FlayerList = data;
    },
    SET_PRIZE_LIST(state, data) {
      state.PrizeList = data;
    },
    SET_CUP_LIST(state, data) {
      state.CupList = data;
    },
    SET_MEDALS_LIST(state, data) {
      state.MedalsList = data;
    },
    SET_PLAKETS_LIST(state, data) {
      state.PlaketsList = data;
    },
    SET_FIGURINES_LIST(state, data) {
      state.FigurinesList = data;
    },
    SET_CLOCKS_LIST(state, data) {
      state.ClocksList = data;
    },
    SET_DIPLOMS_LIST(state, data) {
      state.DiplomsList = data;
    },
    SET_LENTIES_LIST(state, data) {
      state.LentiesList = data;
    },
    SET_MEDALNITS_LIST(state, data) {
      state.MedalnitsList = data;
    },
    SET_UF_INFO_LIST(state, data) {
      state.UfInfoList = data;
    },
  },
  actions: {
    toggle({ commit }) {
      commit('toggle');
    },
    toggle_h({ commit }) {
      commit('toggle');
    },
    async fetchUfData({ commit }) {
      try {
        const tableData = await getUfData();
        const elem = { ...tableData };
        const obj = Object.values(elem);
        const souvenirData = await getSouvenirData();
        const souvenirElem = {...souvenirData};
        const souvObj = Object.values(souvenirElem);
        const brandData = await getBrandData();
        const brandElem = {...brandData};
        const brandObj = Object.values(brandElem);
        const calendarData = await getCalendarData();
        const calendarElem = {...calendarData};
        const calendarObj = Object.values(calendarElem);
        const flayerData = await getFlayerData();
        const flayerElem = {...flayerData};
        const flayerObj = Object.values(flayerElem);
        const prizeData = await getPrizeData();
        const prizeElem = {...prizeData};
        const prizeObj = Object.values(prizeElem);
        const cupData = await getCupsData();
        const cupElem = {...cupData};
        const cupObj = Object.values(cupElem);
        const medalsData = await getMedalsData();
        const medalsElem = {...medalsData};
        const medalsObj = Object.values(medalsElem);
        const plaketsData = await getPlaketsData();
        const plaketsElem = {...plaketsData};
        const plaketsObj = Object.values(plaketsElem);
        const figurinesData = await getFigurineData();
        const figurinesElem = {...figurinesData};
        const figurinesObj = Object.values(figurinesElem);
        const clocksData = await getClocksData();
        const clocksElem = {...clocksData};
        const clocksObj = Object.values(clocksElem);
        const diplomsData = await getDiplomsData();
        const diplomsElem = {...diplomsData};
        const diplomsObj = Object.values(diplomsElem);
        const lentiesData = await getLentiesData();
        const lentiesElem = {...lentiesData};
        const lentiesObj = Object.values(lentiesElem);
        const medalnitsData = await getMedalistsData();
        const medalnitsElem = {...medalnitsData};
        const medalnitsObj = Object.values(medalnitsElem);
        const UfInfoData = await getUfPageInfoData();
        const UfInfoElem = {...UfInfoData};
        const UfInfoObj = Object.values(UfInfoElem);
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line prefer-const,no-restricted-syntax
        // console.log(this.obj);
        commit('SET_UF_LIST', obj);
        commit('SET_SOUVENIR_LIST', souvObj);
        commit('SET_BRAND_LIST', brandObj);
        commit('SET_CALENDAR_LIST', calendarObj);
        commit('SET_FLAYER_LIST', flayerObj);
        commit('SET_PRIZE_LIST', prizeObj);
        commit('SET_CUP_LIST', cupObj);
        commit('SET_MEDALS_LIST', medalsObj);
        commit('SET_PLAKETS_LIST', plaketsObj);
        commit('SET_FIGURINES_LIST', figurinesObj);
        commit('SET_CLOCKS_LIST', clocksObj);
        commit('SET_DIPLOMS_LIST', diplomsObj);
        commit('SET_LENTIES_LIST', lentiesObj);
        commit('SET_MEDALNITS_LIST', medalnitsObj);
        commit('SET_UF_INFO_LIST', UfInfoObj);
      } catch (error) {
        // console.log(error);
      }
    },
  },
  modules: {},
});
