import { createRouter, createWebHistory } from 'vue-router';
import PrintView from '@/views/PrintView.vue';
import HomeView from '../views/HomeView.vue';
import DynamicPage from '../views/DynamicPage.vue';
import SouvenirPostPage from '../views/SouvenirPostPage.vue';
import BrandPostPage from '../views/BrandPostPage.vue';
import CalendarPostPage from "../views/CalendarPostPage.vue";
import FlayerPostPage from "../views/FlayerPostPage.vue";
import PrizePostPage from "../views/PrizePostPage.vue";
import CupPostPage from "../views/CupPostPage.vue";
import MedalsPostPage from "../views/MedalsPostPage.vue";
import PlaketsPostPage from "../views/PlaketsPostPage.vue";
import FigurinesPostPage from "../views/FigurinesPostPage.vue";
import ClocksPostPage from "../views/ClocksPostPage.vue";
import DiplomsPostPage from "../views/DiplomsPostPage.vue";
import LentiesPostPage from "../views/LentiesPostPage.vue";
import MedalnitsPostPage from "../views/MedalnitsPostPage.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/print',
    name: 'PrintView',
    component: PrintView,
  },
  {
    path: '/DynamicPage/:id',
    name: 'DynamicPage',
    component: DynamicPage,
  },
  {
    path: '/SouvenirPostPage/:id',
    name: 'SouvenirPostPage',
    component: SouvenirPostPage,
  },
  {
    path: '/FigurinesPostPage/:id',
    name: 'FigurinesPostPage',
    component: FigurinesPostPage,
  },
  {
    path: '/ClocksPostPage/:id',
    name: 'ClocksPostPage',
    component: ClocksPostPage,
  },
  {
    path: '/MedalnitsPostPage/:id',
    name: 'MedalnitsPostPage',
    component: MedalnitsPostPage,
  },
  {
    path: '/PlaketsPostPage/:id',
    name: 'PlaketsPostPage',
    component: PlaketsPostPage,
  },
  {
    path: '/CupPostPage/:id',
    name: 'CupPostPage',
    component: CupPostPage,
  },
  {
    path: '/DiplomsPostPage/:id',
    name: 'DiplomsPostPage',
    component: DiplomsPostPage,
  },
  {
    path: '/LentiesPostPage/:id',
    name: 'LentiesPostPage',
    component: LentiesPostPage,
  },
  {
    path: '/MedalsPostPage/:id',
    name: 'MedalsPostPage',
    component: MedalsPostPage,
  },
  {
    path: '/BrandPostPage/:id',
    name: 'BrandPostPage',
    component: BrandPostPage,
  },
  {
    path: '/CalendarPostPage/:id',
    name: 'CalendarPostPage',
    component: CalendarPostPage,
  },
  {
    path: '/FlayerPostPage/:id',
    name: 'FlayerPostPage',
    component: FlayerPostPage,
  },
  {
    path: '/PrizePostPage/:id',
    name: 'PrizePostPage',
    component: PrizePostPage,
  },
  {
    path: '/souvenir',
    name: 'SouvenirView',
    component: () => import(/* webpackChunkName: "about" */ '../views/SouvenirView.vue'),
  },
  {
    path: '/advertising',
    name: 'advertising',
    component: () => import(/* webpackChunkName: "about" */ '../views/OutdoorAdvertisingView.vue'),
  },
  {
    path: '/brand',
    name: 'BrandView',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrandView.vue'),
  },
  {
    path: '/printing',
    name: 'PrintingView',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrintingView.vue'),
  },
  {
    path: '/contacts',
    name: 'ContactsView',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactsView.vue'),
  },
  {
    path: '/UF-Print',
    name: 'UFView',
    component: () => import(/* webpackChunkName: "about" */ '../views/UFView.vue'),
  },
  {
    path: '/Rewards',
    name: 'RewardsView',
    component: () => import(/* webpackChunkName: "about" */ '../views/RewardsView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
