<template>
  <div class="container" :class="{active: flayersActive}">
    <div class="overflow">
      <div class="form-block">
        <button class="close" @click='onClick'></button>
        <h2>Форма заказа штучно</h2>
        <form @submit.prevent="submitForm">
          <label for="product">Выберите товар:<select id="product" v-model="product">
            <option value="72 Карманный календарь 1000 шт, 2 стороны">Карманный календарь 1000 шт, 2
              стороны
            </option>
            <option value="198 Флаеры А6 1000 шт, 1 сторона">Флаеры А6 1000 шт, 1 сторона</option>
            <option value="365 Флаеры А6 1000 шт, 2 стороны">Флаеры А6 1000 шт, 2 стороны</option>
          </select></label>
          <label for="quantity">Количество комплектов:
            <input type="number" id="quantity" v-model="quantity" min="1" max="1" required>
          </label>
          <label for="size2">email: <input type="email" id="mail" v-model="mail"
                                           required></label>
          <label for="phone">Телефон:<input type="tel" id="phone" v-model="phone"
                                            inputmode="numeric"
                                            v-mask="'+375 (##) ###-##-##'"
                                            title="+375291111111" required
                                            placeholder="+375293544005">
          </label>
          <p class="total">Итого: {{ total }} BYN</p>
          <button type="submit" class="submit">Отправить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'FlayersFormSingle',
  props: {},
  data: () => ({
    productName: '',
    product: '',
    quantity: 1,
    mail: '',
    phone: '',
  }),
  computed: {
    flayersActive() {
      return this.$store.state.flayersActive;
    },
    total() {
      const sum = Number(this.product.split(' ')[0]) * Number(this.quantity);
      // eslint-disable-next-line no-restricted-globals
      return isNaN(sum) ? 0 : sum;
    },
  },
  methods: {
    ...mapMutations(['SET_FLAYERS_ACTIVE']),
    onClick() {
      this.SET_FLAYERS_ACTIVE(false);
    },
    submitForm() {
      // const orderData = {
      //   productName: this.product.slice(1),
      //   quantity: this.quantity,
      //   size1: this.size1,
      //   size2: this.size2,
      //   total: this.total,
      //   mail: this.mail,
      //   phone: this.phone,
      // };
      // console.log(orderData);
      // здесь можно отправить данные на сервер
      // и очистить поля формы после успешной отправки
      this.productName = '';
      this.product = '';
      this.quantity = 1;
      this.size1 = '';
      this.size2 = '';
      this.mail = '';
      this.phone = '';
    },
  },
};
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>

.container {
  display: none;
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
}

.active {
  display: block;
}

.overflow {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;

  .form-block {
    -webkit-box-shadow: 7px 4px 16px 0 rgba(34, 60, 80, 0.33);
    -moz-box-shadow: 7px 4px 16px 0 rgba(34, 60, 80, 0.33);
    box-shadow: 7px 4px 16px 0 rgba(34, 60, 80, 0.33);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    z-index: 4;
    margin: 100px auto auto;
    width: 30%;
    height: auto;
    background-color: #fff;

    h2 {
      font-size: 28px;
      color: $main-word-color;
      margin-bottom: 10px;
    }

    .close {
      cursor: pointer;
      align-self: flex-end;
      width: 16px;
      height: 16px;
      border-radius: 100px;
      border: none;
      background: linear-gradient(45deg, transparent calc(50% - 3px), #000000 calc(50% - 3px),
        #000000 calc(50% + 3px), transparent calc(50% + 3px)),
      linear-gradient(-45deg, transparent calc(50% - 3px), #000000 calc(50% - 3px),
          #000000 calc(50% + 3px), transparent calc(50% + 3px));
    }

    form {
      width: 80%;
      height: 80%;
      display: flex;
      flex-direction: column;
      position: relative;

      select {
        height: 40px;
        font-size: 16px;
        border: 1px solid $main-word-color;
        border-radius: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }

      //noinspection SassScssResolvedByNameOnly
      input {
        height: 40px;
        font-size: 16px;
        border: 1px solid $main-word-color;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }

      label {
        display: contents;
        color: #212121;
      }

      //noinspection SassScssResolvedByNameOnly
      .total {
        margin-top: 10px;
        font-size: 24px;
        color: $main-word-color;
      }

      .submit {
        align-self: center;
        margin-top: 15px;
        height: 30px;
        width: 100px;
        background-color: $main-word-color;
        border-radius: 30px;
        border: none;
        color: #fff;
        font-weight: 300;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1570px) {
  .container {
    .form-block {
      margin-top: 40px;
    }
  }
}

@media(max-width: 1201px) {
  .container {
    .form-block {
      width: 40%;
    }
  }
}

@media (max-width: 900px) {
  .container {
    .form-block {
      width: 50%;
    }
  }
}

@media (max-width: 600px) {
  .container {
    .form-block {
      width: 90%;
    }
  }
}
</style>
