<template>
  <div class="second-block">
    <div class="content-block">
      <h2>Полиграфия и широкоформатная печать</h2>
      <p>Оперативная лазерная и широкоформатная печать. Офсетная печать визиток, листовок,
        календарей. Скидки на большие партии.</p>
      <router-link :active-class="'active-link'"
                   class="nav-item" to="/printing"><button type="button">заказать онлайн</button>
      </router-link>
    </div>
    <div class="block-background"></div>
  </div>
</template>

<script>
export default {
  name: 'AboutBlock',
};
</script>

<style lang="scss" scoped>
.second-block {
  height: 470px;
  width: 100%;
  background-color: $second-main-bg;
  overflow: hidden;
  position: relative;

  .block-background {
    width: 100%;
    height: 100%;
    margin-left: 14%;
    background-size: auto 100%;
    background-image: url("@/assets/image/secondBg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-position-x: 40px;
    background-attachment: scroll;
    animation: ani 5s linear infinite;
    z-index: 0;
  }

  .content-block {
    position: absolute;
    height: 270px;
    width: 380px;
    z-index: 1;
    right: 23%;
    top: 20%;

    h2 {
      margin: 0 0 6px 0;
      font-weight: 500;
      font-size: 38px;
      line-height: 38px;
      color: $main-word-color;
    }

    p {
      font-size: 18px;
      line-height: 23px;
      color: $text-light;
      font-weight: 300;
      margin-bottom: 17px;
    }

    button {
      margin-top: 19px;
      border: none;
      cursor: pointer;
      color: $button-text-color-light;
      font-weight: 400;
      line-height: 25px;
      font-size: 16px;
      text-transform: uppercase;
      width: 180px;
      height: 44px;
      background: $button-color;
      border-radius: 30px;
    }
  }
}

@media (max-width: 1700px) {
  .second-block {
    .block-background {
      margin-left: 5%;
      background-position-x: 20px;
    }

    .content-block {
      right: 13%;
    }
  }
}

@media (max-width: 1250px) {
  .second-block {
    height: 420px;

    .block-background {
      margin-left: 0;
      background-position-x: 20px;
    }

    .content-block {
      width: 300px;
      right: 5%;
    }
  }
}

@media (max-width: 960px) {
  .second-block {
    height: 240px;

    .content-block {
      width: 250px;
      top: 3%;
      right: 8%;

      h2 {
        font-size: 23px;
      }

      p {
        font-size: 16px;
        margin-bottom: 0;
      }

      button {
        margin-top: 10px;
        height: 30px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 640px) {
  .second-block {
    height: 450px;
    display: flex;
    position: unset;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    .content-block {
      position: unset;
      width: 70%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      h2 {
        font-size: 28px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
      }

      button {
        margin-bottom: 5px;
        margin-top: 10px;
        height: 30px;
        font-size: 16px;
      }
    }
  }
}

@keyframes ani {
  0% {
    transform: translateX(0px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(19px);
    animation-timing-function: linear;
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
