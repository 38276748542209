<template>
  <div class="container">
    <div class="image">
      <img alt="descr-img" :src='imageUrl'>
    </div>
    <div class="content">
      <div class="description">
        <h3>{{ name }} </h3>
        <p>{{ description }}</p>
      </div>
      <div class="price">
        <div class="standard">
          <h6>Стандарт ({{ stCount }})</h6>
          <p class="price-description">{{ stValue }}</p>
        </div>
        <div class="wholesale">
          <h6>Опт ({{ wholeCount }})</h6>
          <p class="price-description">{{ wholeValue }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    stCount: {
      type: String,
      default: '0',
    },
    stValue: {
      type: String,
      default: 'Договорная',
    },
    wholeValue: {
      type: String,
      default: 'Договорная',
    },
    wholeCount: {
      type: String,
      default: '0',
    },
    name: {
      type: String,
      default: 'Товар не найден',
    },
    imageUrl: {
      type: String,
    },
    description: {
      type: String,
      default: 'Уточняйте у оператора',
    },
  },
  // computed: {
  //   imgUrl() {
  //     // eslint-disable-next-line global-require,import/no-dynamic-require
  //     return require(`@/assets/image/tableImages/${this.imageUrl}`);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
h6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: $text-dark;
}

.price-description {
  font-size: 20px;
  font-weight: 300;
  color: $text-dark;
}

.container {
  display: flex;
  background-color: #f0f0f0;
  width: clamp(200px, 95%, 1160px);
  height: 95px;
  margin-bottom: 5px;
  //overflow: hidden;

  .image {
    width: 95px;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: calc(100% - 95px);
    display: flex;
  }

  .description {
    width: 70%;
    height: 100%;
    text-align: left;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $main-word-color;
    }
  }

  .price {
    display: flex;
    width: 30%;
    height: 100%;

    .standard {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 10%;
        height: 80%;
        width: 2px;
        background: $main-word-color;
      }
    }

    .wholesale {
      width: 50%;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 10%;
        height: 80%;
        width: 2px;
        background: $main-word-color;
      }
    }
  }

}

@media (max-width: 1250px) {
  .container {
    .description {
    }

    .standard {
      //width: calc((50% - 95px) / 2);

    }

    .wholesale {
      //width: calc((50% - 95px) / 2);

    }
  }
}

@media (max-width: 950px) {
  .container {
    height: 160px;

    .image {
      width: 160px;
    }

    .content {
      flex-direction: column;

      width: calc(100% - 160px);

      .description {
        height: 70%;
        width: 100%;
      }

      .price {
        width: 100%;
      }

      .standard {
        height: 100%;
        width: 50%;
        flex-direction: column;
        position: relative;

        &::before {
          display: none;
        }
      }

      .wholesale {
        height: 100%;
        width: 50%;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 640px) {
  .container {
    height: 170px;

    .content {
      width: calc(100% - 170px);

      .standard {
        justify-content: space-around;
      }

    }

    .image {
      width: 170px;
    }
  }
}

@media (max-width: 450px) {
  .container {
    flex-direction: row;
    height: 200px;
    position: relative;

    .image {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 120px;
      height: 120px;
    }

    .content {
      width: 100%;

      .description {
        width: calc(100% - 125px);
        align-self: self-end;
        height: 125px;

        h3 {
          font-size: 18px;
          padding-left: 5px;
        }

        p {
          padding-left: 5px;
        }
      }

      .price {
        height: calc(100% - 125px);
      }
    }
  }
}
@media (max-width: 300px){
  .container {
    height: 250px;
    .content {
      .description {
        height: 175px;
        h3{
          font-size: 15px;
        }
        p{
          font-size: 14px;
        }
      }
    }
  }
}
</style>
