import {createApp} from 'vue';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles.scss';

createApp(App).use(store).use(router).use(VueTheMask)
  .mixin({
    beforeCreate() {
      this.$store.dispatch('fetchUfData');
    },
  })
  .mount('#app');
