<template>
  <div class="advertising-block">
    <div class="content-block">
      <h2>Наружная реклама</h2>
      <p>Воплощаем в жизнь проекты любой сложности. Посмотрите наше портфолио и узнайте стоимость
        своей вывески онлайн.</p>
      <div class="brand-buttons">
        <router-link :active-class="'active-link'"
                     class="nav-item" to="/advertising">
          <button type="button">подробнее</button>
        </router-link>

        <router-link :active-class="'active-link'"
                     class="nav-item" to="/advertising">
        <button type="button" class="light-button">калькулятор</button>
        </router-link>
      </div>
    </div>
    <div class="block-background"></div>
  </div>
</template>

<script>
export default {
  name: 'AdvertisingBlock',
};
</script>

<style lang="scss" scoped>
.advertising-block {
  height: 470px;
  width: 100%;
  background: linear-gradient(90deg, #D2D0CC 0%, #D2D0CC 49%, #E1E1DF 50%, #E1E1DF 100%);
  overflow: hidden;
  position: relative;

  .block-background {
    width: 100%;
    height: 100%;
    background-size: auto 100%;
    background-image: url("@/assets/image/thirdBg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    z-index: 0;
  }

  .content-block {
    position: absolute;
    height: 270px;
    width: 380px;
    z-index: 1;
    right: 23%;
    top: 20%;

    h2 {
      margin: 0 0 6px 0;
      font-weight: 500;
      font-size: 38px;
      line-height: 38px;
      color: $main-word-color;
    }

    p {
      font-size: 18px;
      line-height: 23px;
      color: $text-dark;
      font-weight: 300;
      margin-bottom: 17px;
    }

    .brand-buttons {
      display: flex;
      gap: 10px;
    }

    button {
      margin-top: 19px;
      border: none;
      cursor: pointer;
      color: $button-text-color-light;
      font-weight: 400;
      line-height: 25px;
      font-size: 16px;
      text-transform: uppercase;
      width: 180px;
      height: 44px;
      background: $button-color;
      border-radius: 30px;
    }
    .light-button{
      background-color: #fff;
      color: #212121;
    }
  }
}
@media (max-width: 1700px) {
  .advertising-block {

    .content-block {
      right: 13%;
    }
  }
}

@media (max-width: 1250px) {
  .advertising-block {
    height: 420px;

    .block-background {
      margin-left: 0;
      background-position-x: 30%;
    }

    .content-block {
      width: 300px;
      right: 5%;
    }
  }
}

@media (max-width: 960px) {
  .advertising-block {
    height: 240px;

    .content-block {
      width: 250px;
      right:20%;
      top: 10%;

      h2 {
        font-size: 23px;
      }

      p {
        font-size: 16px;
        margin-bottom: 0;
      }

      button {
        margin-top: 10px;
        height: 30px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 640px) {
  .advertising-block {
    height: 450px;
    display: flex;
    position: unset;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    background: #dedddb;
    .block-background {
      margin-left: 0;
      background-position-x: 25%;
    }
    .content-block {
      position: unset;
      width: 70%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      h2 {
        font-size: 23px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
      }

      .brand-buttons{
        flex-direction: column;
      }

      button:last-of-type {
        margin-bottom: 10px;
        margin-top: 1px;
        height: 30px;
        font-size: 16px;
      }
    }
  }
}
</style>
