<template>
  <div class="nav-mobile">
    <div class="logo-mobile">
      <p>
        <router-link to="/">BOGACHI.BIZ</router-link>
      </p>
    </div>
    <div class="phone-number-mob">
      <a href="tel:+375339938005">+375 33 993 80 05</a>
    </div>
    <BurgerButton @click="isActive = !isActive"/>
  </div>
  <div class="nav-panel" :class="{active: isActive}">
    <router-link @click="toggle(); isActive = !isActive" to="/" class=nav-logo>
      <img src="@/assets/image/logo.jpg" alt="logo">
    </router-link>
    <nav class="nav-center">
      <router-link @click="toggle(); isActive = !isActive" @keyup.left="toggle"
                   :active-class="'active-link'"
                   class="nav-item" to="/print">ПЕЧАТЬ
      </router-link>
      <router-link @click="toggle(); isActive = !isActive" :active-class="'active-link'"
                   class="nav-item" to="/UF-Print">Уф-печать
      </router-link>
      <router-link @click="toggle(); isActive = !isActive" :active-class="'active-link'"
                   class="nav-item" to="/souvenir"
      >БИЗНЕС-СУВЕНИРЫ
      </router-link>
      <router-link @click="toggle(); isActive = !isActive" :active-class="'active-link'"
                   class="nav-item" to="/advertising">НАРУЖНАЯ РЕКЛАМА
      </router-link>
      <router-link @click="toggle(); isActive = !isActive" :active-class="'active-link'"
                   class="nav-item" to="/brand">БРЕНДИРОВАНИЕ
      </router-link>
      <router-link @click="toggle(); isActive = !isActive" :active-class="'active-link'"
                   class="nav-item" to="/printing">ПОЛИГРАФИЯ
      </router-link>
      <router-link @click="toggle(); isActive = !isActive" :active-class="'active-link'"
                   class="nav-item" to="/Rewards">Награды
      </router-link>
      <router-link @click="toggle(); isActive = !isActive" :active-class="'active-link'"
                   class="nav-item" to="/contacts">КОНТАКТЫ
      </router-link>
    </nav>
    <div class="nav-contacts">
      <div class="phone-number">
        <a href="tel:+375339938005">+375 33 993 80 05</a>
      </div>
      <a href="https://www.instagram.com/bogachi.biz/?igshid=YmMyMTA2M2Y%3D"><img
        src="@/assets/image/instagramm.svg" alt="instagram"></a>
    </div>
  </div>
</template>

<script>
import BurgerButton from '@/components/BurgerButton.vue';
import {mapMutations, mapState} from "vuex";

export default {
  name: 'HeaderComponent',
  components: {
    BurgerButton,
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    ...mapState(['burgerActive']),
  },
  methods: {
    ...mapMutations(['toggle']),
  },
};
</script>

<style lang="scss" scoped>
.nav-mobile {
  height: 24px;
  background-color: $mobile-nav-color;
  display: none;
}

.phone-number-mob {
  display: none;

  a {
    font-weight: 300;
    font-size: 16px;
    color: #fff;
  }
}

.nav-logo {
  margin-left: 40px;
  width: 225px;
}

.nav-panel {
  position: fixed;
  width: 100%;
  background-color: rgba(33, 33, 33, 1);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.nav-center {
  display: flex;
  gap: 30px;
  margin-right: auto;
  margin-left: auto;
}

.nav-item {
  font-size: 16px;
  color: $main-word-color;
  font-family: 'FuturaPT', sans-serif;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    display: block;
    height: 2px;
    width: 0;
    background: $main-word-color;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
}

.active-link {
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    display: block;
    height: 2px;
    width: 100%;
    background: $main-word-color;
  }
}

.nav-contacts {
  display: flex;
  gap: 35px;
  margin-right: 40px;
  align-items: center;

  .phone-number {
    a {
      font-weight: 400;
      font-size: 20px;
      color: #fff;
    }
  }

  .nav-instagram {
    margin-top: 7px;
    background-color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
}

@media (max-width: 1570px) {
  .nav-logo {
    width: auto;
  }
  .nav-mobile {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;

    .logo-mobile {
      a {
        color: #fff;
        line-height: 22px;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  .nav-panel {
    position: unset;
    height: 100vh;
    display: none;
    flex-direction: column;
    justify-content: space-between;

    .nav-logo {
      padding: 20px;
      margin-left: 0;
    }

    .nav-center {
      flex-direction: column;
      text-align: center;
    }

    .nav-contacts {
      flex-direction: column;
      gap: 10px;
      padding: 20px 0;
      margin: 0;
    }
  }
  .active {
    display: flex;
    animation: ani 0.8s ease-in-out;
  }

  @keyframes ani {
    0% {
      opacity: 0.4
    }
    100% {
      opacity: 1
    }
  }
}

@media (max-width: 640px) {
  .phone-number-mob {
    display: block;

    a {
      font-weight: 300;
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>
