import ky from 'ky';

// eslint-disable-next-line import/prefer-default-export,consistent-return
export async function getTableData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/tables?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.image = `https://cms.bogachi.biz${el.attributes.image.data.attributes.url}`;
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}

export async function getBannerData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/baner-prints?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.image = `https://cms.bogachi.biz${el.attributes.image.data.attributes.url}`;
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getLaserPrintData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/laser-prints?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.image = `https://cms.bogachi.biz${el.attributes.image.data.attributes.url}`;
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getOffsetPrintData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/offset-prints?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.image = `https://cms.bogachi.biz${el.attributes.image.data.attributes.url}`;
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getFlayersData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/flayers?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.image = `https://cms.bogachi.biz${el.attributes.image.data.attributes.url}`;
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getDiscountsData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/discounts?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.image = `https://cms.bogachi.biz${el.attributes.image.data.attributes.url}`;
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}

export async function getUfData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/uf-prints?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getSouvenirData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/souvenir-prints?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}

export async function getBrandData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/brend-prints?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getCalendarData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/poligrafia-calendars?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getFlayerData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/poligrafia-flayers?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getPrizeData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/prizes?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getCupsData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/cups?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getMedalsData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/medals?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getPlaketsData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/plakets?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getFigurineData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/figurines?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getClocksData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/clocks?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getDiplomsData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/diploms?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getLentiesData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/lenties?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getMedalistsData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/medalists?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover1 = `https://cms.bogachi.biz${el.attributes.cover1.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.cover2 = `https://cms.bogachi.biz${el.attributes.cover2.data.attributes.url}`;
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
export async function getUfPageInfoData() {
  try {
    const response = await ky.get('https://cms.bogachi.biz/api/uf-page-infos?populate=*').json();
    const data = [...response.data];
    data.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.attributes.photo = el.attributes.album.data.map((item) => `https://cms.bogachi.biz${item.attributes.url}`);
      // eslint-disable-next-line no-param-reassign
      el.attributes.album = el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.photo;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.createdAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.publishedAt;
      // eslint-disable-next-line no-param-reassign
      delete el.attributes.updatedAt;
      // eslint-disable-next-line no-param-reassign
    });
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}
