<template>
  <div class="container">
    <div class="image">
      <div class="image-block">
        <div class="notepad">
          <img src="@/assets/image/cup.png" alt="notepad" srcset="">
        </div>
        <div class="flash">
          <img src="@/assets/image/medal.png" alt="flash" srcset="">
        </div>
      </div>
    </div>
    <div class="content">
      <h2>Наградная продукция</h2>
      <p>Кубки, наградные доски, медали, статуэтки. Качество и уникальность в каждой детали</p>
      <router-link :active-class="'active-link'"
                   class="nav-item" to="/Rewards">
        <button type="button">Заказать онлайн</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrizesBlock',
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 20px;
  height: 470px;
  width: 100%;
  background-color: #EAE4DD;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  flex-direction: row-reverse;

  .content {
    margin-right: 13%;
    margin-left: 20%;
    height: 270px;
    width: 380px;

    h2 {
      margin: 0 0 6px 0;
      font-weight: 500;
      font-size: 38px;
      line-height: 38px;
      color: $main-word-color;
    }

    p {
      font-size: 18px;
      line-height: 23px;
      color: $text-dark;
      font-weight: 300;
      margin-bottom: 17px;
    }

    .brand-buttons {
      display: flex;
      gap: 10px;
    }

    button {
      margin-top: 19px;
      border: none;
      cursor: pointer;
      color: $button-text-color-light;
      font-weight: 400;
      line-height: 25px;
      font-size: 16px;
      text-transform: uppercase;
      width: 180px;
      height: 44px;
      background: $button-color;
      border-radius: 30px;
    }
  }

  .image {
    height: 100%;
    width: 380px;
    margin-right: 30%;

    .image-block {
      position: relative;
      width: 100%;
      height: 100%;

      .notepad {
        img {
          margin-top: 50px;
          margin-left: 100%;
          max-height: 370px;
          display: block;
          animation: notepad-anim 3.4s infinite linear;
        }
      }

      .flash {
        img {
          //right: -60%;
          top: -80px;
          position: absolute;
          max-height: 280px;
          display: block;
             animation: flash-anim 3.4s infinite;
        }
      }
    }
  }
}
@media (min-width: 1700px){
  .container{
    .content{
      margin-right: 23%;
    }
  }
}
@media (max-width: 1500px) {
  .container{
    .image{
      .image-block{
        .notepad{
          img{
            margin-left: 380px;
          }
        }
        .flash{
          img{
            right: 0;
          }
        }
      }
    }
  }
}
@media(max-width: 1250px) {
  .container {
    height: 420px;

    .content {
      margin-right: 5%;
      width: 300px;
    }

    .image {
      margin-right: 15%;

      .image-block {
        max-height: 420px;

        .notepad {
          img {
            max-height: 320px;
          }
        }

        .flash {
          img {
            max-height: 250px;
            display: block;
          }
        }
      }
    }

  }
}

@media(max-width: 960px) {
  .container {
    height: 240px;

    .content {
      height: 100%;
      margin-top: 5%;
      width: 250px;
      margin-left:20%;
      //margin-right: 8%;
      h2 {
        font-size: 23px;
      }

      p {
        font-size: 16px;
        margin-bottom: 5px;
      }

      button {
        height: 30px;
        font-size: 16px;
      }
    }

    .image {
      .image-block {
        max-height: 240px;

        .notepad {
          img {
            margin-left: 80%;
            margin-top: 20px;
            max-height: 200px;
          }
        }

        .flash {
          img {
            max-height: 180px;
            display: block;
            right: calc(20%);
            top: -50px;
          }
        }
      }
    }

  }
}

@media (max-width: 640px) {
  .container {
    height: 450px;
    flex-direction: column;

    .content {
      background-color: #EAE4DD;
      width: 100%;
      margin: 0;
      text-align: center;
      p{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .image {
      width: 100%;
      height: 240px;

      .image-block {
        width: 100%;
        height: 240px;

        .notepad {
          img {
            margin-left: 60%;
            display: block;
            animation: notepad-anim 6s infinite linear;
          }
        }

        .flash {
          img {
            position: absolute;
            display: block;
            max-height: 150px;
            top: -40px;
            right: 40%;
            animation: flash-anim 6s infinite;
          }
        }
      }
    }
  }
}

@keyframes flash-anim {
  0% {
    transform: translateX(0px) translateY(0px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(9px) translateY(2px);
    animation-timing-function: linear;
  }
  100% {
    transform: translateX(-1px) translateY(0px);
  }
}

@keyframes notepad-anim {
  0% {
    transform: translateX(0px) translateY(0px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(5px) translateY(-17px);
    animation-timing-function: linear;
  }
  100% {
    transform: translateX(0px) translateY(1px);
  }
}
</style>
