<template>
  <div class="container">
    <div class="copyright">© 2022 ООО "БОГАЧИ"</div>
    <div class="nav-list">
      <ul>
        <router-link to="/print">
          <li>Печать</li>
        </router-link>
        <router-link to="/advertising">
          <li>Наружная реклама</li>
        </router-link>
        <router-link to="/souvenir">
          <li>Бизнес-сувениры</li>
        </router-link>
        <router-link to="/brand">
          <li>Брендирование</li>
        </router-link>
        <router-link to="/printing">
          <li>полиграфия</li>
        </router-link>
        <router-link to="/contacts">
          <li>контакты</li>
        </router-link>
      </ul>
    </div>
    <div class="scroll" @click="scrollToTop" @keyup.left="scrollToTop"> Наверх ↑</div>
  </div>
</template>

<script>

export default {
  name: 'FooterComponent',
  methods: {
    scrollToTop() {
      const duration = 700;
      let difference = window.scrollY;
      const step = (10 * difference) / duration;
      const timer = setInterval(() => {
        difference -= step;
        window.scrollTo(0, difference);
        document.body.setAttribute('data-scrollable', 'true');
        if (window.scrollY === 0) {
          document.body.removeAttribute('data-scrollable');
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background-color: rgba(33, 33, 33, 1);
  color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 20px 0;

  .copyright {
    width: 140px;
    white-space: nowrap;
  }

  .scroll {
    width: 140px;
    cursor: pointer;
  }

  ul {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    li {
      list-style: none;
      white-space: nowrap;
      font-size: 16px;
      line-height: 22px;
      color: $main-word-color;
      text-transform: uppercase;
    }
  }
}

@media(max-width: 1200px) {
  .container {
    height: max-content;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .copyright {
      order: 2;
      width: unset;

    }

    .scroll {
      order: 3;
      width: unset;
    }

    ul {
      justify-content: center;
      order: -1;

      li {
        line-height: 16px;
      }
    }
  }
}
</style>
