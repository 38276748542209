<template>
  <div class="main">
    <h1>{{ obj.attributes.title }}</h1>
    <div class="content">
      <p>{{ obj.attributes.description }}</p>
      <div v-for="(photo, index) in obj.attributes.album" :key="index" class="photos">
        <img :src="photo" alt="photo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FigurinesPostPage',
  computed: {
    obj() {
      return this.$store.getters.FigurinesID(Number(this.$route.params.id));
    },
  },
};
</script>

<style lang="scss" scoped>
.main{
  margin-bottom: 25px;
}
.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  p{
    text-align: center;
    margin-top: 10px;
    width: 80%;
    font-weight: 300;
    font-size: 18px;
  }

  .photos{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    width: 1000px;
    height: 100%;
  }
}
@media (max-width: 1200px){
  .content{
    img{
      width: 800px;
    }
  }
}
@media (max-width: 900px){
  .content{
    img{
      width: 600px;
    }
  }
}
@media (max-width: 700px){
  .content{
    img{
      width: 400px;
    }
  }
}
@media (max-width: 450px){
  .content{
    img{
      width: 300px;
    }
  }
}
</style>
