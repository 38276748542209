<template>
  <div class="first-container">
    <div class="container">
      <div class="page-logo">
        <div class="bo logo-word">
          <img alt="bo" src="@/assets/image/PageLogo/bo.svg">
        </div>
        <div class="ga logo-word">
          <img alt="ga" src="@/assets/image/PageLogo/ga.svg">
        </div>
        <div class="chi logo-word">
          <img alt="chi" src="@/assets/image/PageLogo/chi.svg">
        </div>
      </div>
      <div class="text">Нет времени искать? Мы Вам сами всё расскажем. Закажите звонок.</div>
      <router-link :active-class="'active-link'"
                   class="nav-item" to="/contacts"><button type="button">ОБРАТНЫЙ ЗВОНОК</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBlock',
};
</script>

<style lang="scss" scoped>

.first-container {
  background-color: #000;
  height: 470px;
  width: 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-image: url("@/assets/image/firstBg.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: scroll;
  display: flex;
  align-items: center;

  .container {
    height: 305px;
    width: 320px;
    margin-left: 21.5%;
    margin-top: 45px;

    .page-logo {
      width: 100%;
      display: inline-block;

      .logo-word {
        display: inline-block;
        vertical-align: bottom;
        margin: 6px;
      }
    }

    .text {
      color: $text-light;
      line-height: 23px;
      font-weight: 300;
      font-size: 20px;
      margin-top: 28px;
    }

    button {
      margin-top: 19px;
      border: none;
      cursor: pointer;
      color: $button-text-color-light;
      font-weight: 400;
      line-height: 25px;
      font-size: 16px;
      text-transform: uppercase;
      width: 180px;
      height: 44px;
      background: $button-color;
      border-radius: 30px;
    }
  }
}

@media screen and (max-width: 1230px) {
  .first-container {
    height: 356px;

    .container {
      height: 250px;
      margin-left: 15%;
      margin-top: 0;
    }
  }
}

@media (max-width: 940px) {
  .first-container {

    .container {
      margin-left: 10%;
    }
  }
}

@media (max-width: 639px) {
  .first-container {
    height: 270px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("@/assets/image/firstBgmobile.jpg") center bottom;
    background-size: cover;
    justify-content: center;
    align-items: center;

    .container {
      margin: 0;
      align-content: center;
      text-align: center;
    }
  }
}

@media (max-width: 460px) {
  .first-container {
    height: 470px;
    background-position: center bottom;
    align-items: start;

    .container {
      margin-top: 5%;

      .page-logo {
        .logo-word {
          width: 27%;
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .first-container {
    .container {
      width: 250px;
      .page-logo{
        .logo-word {
          width: 27%;
          img {
            width: 100%;
          }
        }
      }

    }
  }
}
</style>
